import { ARTECO_SER_CODE } from "../actions/types";
import { CIVETWEB_ENTRYPOINT } from "../config/backend";
import { error, logger } from "./logger";
import { addDays } from "./timeHelpers";

const filterFunction = (event) => {
  if(event.eventData) {
    //KSENIA FILTERING
    if(event.eventData?.kseniaData) {
      const kseniaData = event.eventData?.kseniaData;
      if(kseniaData && kseniaData.TIME) return 0
    }
    
    return 1;
  }

  return 1;
}

export function processEvents(events, server, serverDevices, auth, visibility = null, source) {
  return events.map(event => {

    if(visibility === 'hidden' && source === "stop"){
      return false;
    }

    if (server.codeName !== event.serverCodename) {
      return false;
    }  


    let artecoId = `${event.serverCodename}_${event.deviceType}_${event.deviceId}`;
    let artecoEventId = `${event.serverCodename}_${event.deviceType}_${event.id}`;
    let device = event.deviceType === ARTECO_SER_CODE ? serverDevices[event.serverCodename] :   serverDevices[artecoId];

    if (!device) {
      return false;
    }

    //AST-4015
    if(event.category === 301) {
      return false;
    }
    //AST-4015

    let thumbnailUrl = (event.thumbnailUri) ? event.thumbnailUri.replaceAll('\\', '/') : '';
    //nginx
    let thumbnailPath = '';
    if(thumbnailUrl && thumbnailUrl !== "none") {
      thumbnailPath = server.nodeServer ? `${server.protocol}://${server.ip}:${server.port}/thumbnails/${thumbnailUrl}` : `${server.protocol}://${server.ip}:${server.port}/${CIVETWEB_ENTRYPOINT}/thumbnails/${thumbnailUrl}`;
    }
    
    //let thumbnailPath = `${server.protocol}://${server.ip}:${server.port}/${CIVETWEB_ENTRYPOINT}/thumbnails/${thumbnailUrl}`;
    
    let retention_days = device["retention-days"];
    let delete_date_arteco = addDays(event.eventTime, retention_days);

    //TODO: pulirli lato server!!
    let eventParamsUpdate = event.category === 212 ? event.params.replace('\n', ' ') : event.params;


    let additionalData = event.eventData;

    if(event.eventData) {
      try {
        additionalData = JSON.parse(event.eventData);
        //MOCK
        //additionalData.transCam = '10';
        //additionalData.transCam = 'V03220059_4';
        //additionalData.relatedCameras = ['3e655da4a886561dcdc0cfc167170dc_1_10'];
      } catch (e) {
        logger(error, "events", "Error trying parsing additional data for event " + artecoEventId);
        additionalData = event.eventData;
      }
    }

    let newEvent = {
      ...event,
      id: parseInt(event.id),
      artecoId: artecoId,
      artecoEventId: artecoEventId,
      serverId: server._id,
      owner: auth.user.id,
      serverIp: server.ip,
      serverPort: server.port,
      serverProtocol: server.protocol,
      className: 'new',
      thumbnailUri: thumbnailPath,
      notes: event.notes,
      isTruthy: event.isTruthy,
      retention_days: retention_days,
      deleteDate: delete_date_arteco,
      params: event.category === 212 ? eventParamsUpdate.split(' ')[0] : event.params || '', //Filtro necessario per gli eventi targa in qunto dal server arrivano informazioni 'sporche' per gli eventi di tipo LPR
      eventBrand: event.eventBrand,
      eventData: additionalData,
      eventIntegrationType: event.eventIntegrationType,
      visibility: visibility
    }

    return newEvent;

  }).filter(filterFunction);
}

export function sortEventsByEventTimeAC(a,b,sortByEventTime,sortAC){

  const sortOrder = sortAC ? 1 : -1;
  const processedA = a.username !== '' && a.username !== 'System'; // if true = PROCESSED 
  const processedB = b.username !== '' && b.username !== 'System'; // if true = PROCESSED

  if(sortByEventTime){
    //If sortByEventTime == true i sorte all the events (processed or not) by eventTime
    const compareResult = a.eventTime.localeCompare(b.eventTime);
    return sortOrder * compareResult;

  }else{

    //RULES for sorting processed
    //if sortAC == TRUE => at the top, display the UNPROCESSED events sorted by the "eventTime" from the oldest to the most recent, followed by PROCESSED events sorted by "lastUpdateTime" from the oldest to the most recent.
    //if sortAC == FALSE => at the top, display the PROCESSED events sorted by "lastUpdateTime" from the most recent to the oldest, and immediately after, display the UNPROCESSED events sorted by "eventTime" from the most recent to the oldest.

    if (!processedA && !processedB) {
      //Both unprocessed, sort by eventTime
      return sortOrder * a.eventTime.localeCompare(b.eventTime);
    }

    if (!processedA) {
      //Only a is unprocessed, place it first 
      return -1 * sortOrder;
    }
    if (!processedB) {
      //Only b is unprocessed, place it first
      return 1 * sortOrder;
    }

    //Both processed, sort by lastUpdateTime
    return sortOrder * a.lastUpdateTime.localeCompare(b.lastUpdateTime);

  }
}

function matchesSearchTerms(paramsToUse, searchTerms, strict, serverDevices, eventArtecoId, eventId) {
  const queryNum = searchTerms.length;
  const found = searchTerms.map(cleanSearchString => {
      let matches = 0;

      if (paramsToUse.toUpperCase().includes(cleanSearchString.toUpperCase())) {
          matches++;
      }
      if (serverDevices[eventArtecoId] && serverDevices[eventArtecoId].descr.toUpperCase().includes(cleanSearchString.toUpperCase())) {
          matches++;
      }
      if (eventId.toString().includes(cleanSearchString)) {
          matches++;
      }

      return {
          string: cleanSearchString,
          score: matches
      };
  });

  if (queryNum === 0) {
      return true;
  } else {
      let strictWin = false;

      let result = found.reduce((acc, obj) => acc + obj.score, 0);
      let check = found.filter(singleString => singleString.score > 0);

      if (check.length === queryNum) {
          strictWin = true;
      }

      return strict ? (result > 0 && strictWin) : result > 0;
  }
}

function matchesSearchTermsNumPlate(paramsToUse, searchTermsNumPlate) {
  if (searchTermsNumPlate.length === 0) {
      return true;
  }
  return searchTermsNumPlate.some(cleanSearchString =>
      paramsToUse.toUpperCase().includes(cleanSearchString.toUpperCase())
  );
}
export  const diagnosticCategories = [300, 301, 302, 303, 304, 305, 306, 307, 308, 309, 501, 502, 503, 505, 50, 51, 506, 507, 511, 512, 513, 514, 515, 517, 518, 'system', 'network', 'license' ];

export function eventMatchsFilters(event,selectedFilters, serverDevices, activeLayoutIds,  live, lastStop, searchTerms,strict, searchTermsNumPlate, enableNewEventFilter, eventsNotTruthy){

  if (eventsNotTruthy && event.isTruthy === 2) {
    return false; 
  }
  if (enableNewEventFilter && diagnosticCategories.includes(event.category)) {
    return false;
  }
  const eventArtecoId = event.artecoId || `${event.serverCodename}_${event.deviceType}_${event.deviceId}`;

  const improEvt = event?.eventBrand === 'impro' ? true : false;
  const improData = improEvt ? event.eventData : null;
  let eventParams = improData?.transEventDesc ? `${improData.transTrzonename} ${improData.trasnTrdevname} ${improData.transEventDesc} ${improData.transName}` : event.params;
    
  //if the artecoId is not in the serverDevices, we don't want to show the event
  
  if (event.deviceType === ARTECO_SER_CODE) {
    // Check using serverCodename if deviceType is ARTECO_SER_CODE
    if (!serverDevices[event.serverCodename]) {
      return false;
    }
  } else {
    // Check using eventArtecoId for any other deviceType
    if (!serverDevices[eventArtecoId]) {
      return false;
    }
  }
  
  if (noFiltersSet(selectedFilters, live, searchTerms, enableNewEventFilter)) {
    return true;
  }
  if (event.params === undefined || event.params === null) {
    event.params = "";
  }
  if (typeof (event.params.toUpperCase) !== 'function') {
    event.params = event.params.toString();
  }
  const paramsToUse = improEvt ? eventParams : event.params;
  const textMatch = matchesSearchTerms(paramsToUse, searchTerms, strict, serverDevices, eventArtecoId, event.id);

  const serverFilters = enableNewEventFilter ? selectedFilters.servers.codeNames : selectedFilters.servers;
  const activeLayoutArtecoIds = !enableNewEventFilter 
  ? (!selectedFilters.onlyActiveLayoutEvents ? [] : activeLayoutIds)
  : (!Object.values(selectedFilters).some(filterGroup => filterGroup.onlyActiveLayoutEvents) ? [] : activeLayoutIds);
  
 
  const serversMatch = (serverFilters.length === 0) || serverFilters.includes(event.serverCodename);
  const activeLayoutArtecoIdsMatch = !enableNewEventFilter 
  ? (!selectedFilters.onlyActiveLayoutEvents || activeLayoutArtecoIds.includes(eventArtecoId))
  : (!Object.values(selectedFilters).some(filterGroup => filterGroup.onlyActiveLayoutEvents) || activeLayoutArtecoIds.includes(eventArtecoId));

  const timeMatch = (live) || (event.eventTime <= lastStop);

  // const output = textMatch && filtersMatch && serversMatch && timeMatch && vcaFilter && kseniaFilter && activeLayoutArtecoIdsMatch;
  const output =  (eventsNotTruthy || event.isTruthy !== 2) && textMatch && serversMatch && timeMatch && activeLayoutArtecoIdsMatch && (
    enableNewEventFilter 
      ? eventMatchsFiltersCategroyVcsKsenia(event, selectedFilters, enableNewEventFilter, searchTermsNumPlate)
      : eventOldMatchCategoryVcaKseniaFilters(event, selectedFilters, enableNewEventFilter)
  );
  
  return output;

}

const hasActiveFilters = (filterGroup, propertiesToCheck) => {
  return propertiesToCheck.some(property => filterGroup[property] && filterGroup[property].length > 0);
};

export function eventMatchsFiltersCategroyVcsKsenia(event, selectedFilters, enableNewEventFilter, searchTermsNumPlate) {
  const intrusionEvts = kseniaFilterFunction(event, selectedFilters, enableNewEventFilter); // KSENIA
  const ioEvts = ioFilterFunction(event, selectedFilters); // IO
  const videoAnalysisEvts = videoAnalysisFilterFunction(event, selectedFilters, enableNewEventFilter, searchTermsNumPlate); // VCA
  const accessControlEvts = accessControlEvtsFilterFunction(event, selectedFilters); // ACCESS CONTROL
  const intrusionEvtActive = hasActiveFilters(selectedFilters['INTRUSION EVENTS'], ['intrusionStatus', 'intrusionTypes']);
  const ioEvtActive = hasActiveFilters(selectedFilters['IO EVENTS'], ['categories']);
  const videoAnalysisEvtActive = hasActiveFilters(selectedFilters['VIDEO ANALYSIS EVENTS'], ['categories', 'vcaClasses', 'vcaColors']);
  const accessControlEvtActive = hasActiveFilters(selectedFilters['ACCESS CONTROL EVENTS'], ['categories', 'accessControlEventTypes', 'accessControlEventDirection']);

  let result = false;

  if (intrusionEvtActive || ioEvtActive || videoAnalysisEvtActive || accessControlEvtActive) {
    if (intrusionEvtActive) {
      result = result || intrusionEvts;
    }
    if (ioEvtActive) {
      result = result || ioEvts;
    }
    if (videoAnalysisEvtActive) {
      result = result || videoAnalysisEvts;
    }
    if (accessControlEvtActive) {
      result = result || accessControlEvts;
    }
  } else {
    result = intrusionEvts || ioEvts || videoAnalysisEvts || accessControlEvts;
  }

  return result;
}

const accessControlEventTypes = (event, selectedFilters) => {
  const accessControlEventTypesFilters = selectedFilters['ACCESS CONTROL EVENTS']?.accessControlEventTypes || []
  const improEvt = event?.eventBrand === 'impro';
  const improData = event.eventData;
  if (!improEvt || !improData || !improData.transEventDesc) {
    return false;
  }

  const transEventDescLower = improData.transEventDesc.toLowerCase();

  return accessControlEventTypesFilters.some(filter => transEventDescLower.includes(filter.toLowerCase()));

}

const accessControlEventDirection = (event, selectedFilters) => {
  const accessControlEventDirectionFilters = selectedFilters['ACCESS CONTROL EVENTS']?.accessControlEventDirection || [];
  const improEvt = event?.eventBrand === 'impro';
  const improData = event.eventData;
  if (!improEvt) {
    return false;
  }
  const matchDirection = accessControlEventDirectionFilters.map(filter => filter?.toLowerCase()).includes(improData?.transDirection?.toLowerCase());

  return matchDirection;
}

const accessControlEvtsFilterFunction = (event, selectedFilters) => {
  const categoryFilters = (selectedFilters['ACCESS CONTROL EVENTS']?.categories || [])
  const accessControlEventDirectionFilters = selectedFilters['ACCESS CONTROL EVENTS']?.accessControlEventDirection || [];
  const accessControlEventTypesFilters = selectedFilters['ACCESS CONTROL EVENTS']?.accessControlEventTypes || []

  const accessControlCategoryMatch = (categoryFilters.length === 0) || categoryFilters.includes(event.category);
  const accessControlEventTypesMatch = accessControlEventTypes(event, selectedFilters)
  const accessControlEventDirectionMatch = accessControlEventDirection(event, selectedFilters)
  // If all filters are empty, return true
  if (!categoryFilters.length && !accessControlEventDirectionFilters.length && !accessControlEventTypesFilters.length) {
    return true;
  }

  // If only one filter is non-empty, return its match
  if (categoryFilters.length > 0 && !accessControlEventDirectionFilters.length && !accessControlEventTypesFilters.length) {
    return accessControlCategoryMatch;
  }
  if (!categoryFilters.length && accessControlEventDirectionMatch.length > 0 && !accessControlEventTypesFilters.length) {
    return accessControlEventDirectionMatch;
  }
  if (!categoryFilters.length && !accessControlEventDirectionMatch.length && accessControlEventTypesFilters.length > 0) {
    return accessControlEventTypesMatch;
  }

  if (categoryFilters.length > 0) {
    if (accessControlEventDirectionMatch.length > 0 && accessControlEventTypesFilters.length > 0) {
      return accessControlCategoryMatch && (accessControlEventTypesMatch || accessControlEventDirectionMatch);
    }
    if (accessControlEventDirectionFilters.length > 0) {
      return accessControlCategoryMatch && accessControlEventDirectionMatch;
    }
    if (accessControlEventTypesFilters.length > 0) {
      return accessControlCategoryMatch && accessControlEventTypesMatch;
    }
  }

  return accessControlEventTypesMatch || accessControlEventDirectionMatch;

}

export function videoAnalysisFilterFunction(event, selectedFilters, enableNewEventFilter, searchTermsNumPlate) {
  const numPlateMatch = matchesSearchTermsNumPlate(event.params, searchTermsNumPlate);
  const lprCatgoriesFilters = (selectedFilters['VIDEO ANALYSIS EVENTS']?.lprCatgories || [])
  const lprCategoryMatch = (lprCatgoriesFilters.length === 0) || lprCatgoriesFilters.includes(event.category);

  const categoryFilters = (selectedFilters['VIDEO ANALYSIS EVENTS']?.categories || [])
  const filtersVcaMatch = vcaFilterFunction(event,selectedFilters, enableNewEventFilter); 
  const filtersCategoryMatch = (categoryFilters.length === 0) || categoryFilters.includes(event.category);
  if (lprCatgoriesFilters.length > 0) {
    return (filtersCategoryMatch && filtersVcaMatch) || (searchTermsNumPlate.length > 0 ? (numPlateMatch && lprCategoryMatch) : lprCategoryMatch);
  } else {
    return filtersCategoryMatch && filtersVcaMatch;
  }
}

export function ioFilterFunction(event, selectedFilters) {
  const ioCategoryFilters = (selectedFilters['IO EVENTS']?.categories || [])
  const filtersIoEvtCategoryMatch = (ioCategoryFilters.length === 0) || ioCategoryFilters.includes(event.category);
  return filtersIoEvtCategoryMatch;

}

export function eventOldMatchCategoryVcaKseniaFilters(event, selectedFilters, enableNewEventFilter) {
  const categoryFilters = selectedFilters.categories;
  const filtersMatch = (categoryFilters.length === 0) || categoryFilters.includes(event.category);
  // filtrers for third part integrations
  const vcaFilter = vcaFilterFunction(event,selectedFilters, enableNewEventFilter); // VCA
  const kseniaFilter = kseniaFilterFunction(event,selectedFilters, enableNewEventFilter); // KSENIA

  return filtersMatch && vcaFilter && kseniaFilter;
}
function processEventParams(eventParams, t) {
  // Regular expression to capture the drive letter and operation (0 or 1)
  const regex = /([a-zA-Z]:,?\s*)*(\n)?([01])(\n)?/;
  const match = eventParams.match(regex);

  // Check if match exists and is a valid array with matches
  if (match && Array.isArray(match) && match.length > 0) {
    const driveLetter = match[1] ? match[1].trim() : '';  // Handle the drive letter
    const operation = parseInt(match[3]);  // Get the 0 or 1 from the match

    // Depending on the operation (0 or 1), modify eventParams
    if (operation === 0) {
      eventParams = t('EVT_505_ADD', { driveletter: driveLetter });
    } else if (operation === 1) {
      eventParams = t('EVT_505_REMOVE', { driveletter: driveLetter });
    }
  }

  // Return the modified eventParams
  return eventParams;
}

export function eventMatchsDiagnosticFilters(event, selectedFilters, serverDevices, searchTerms, strict, t, serverCodenames) {
  const serverFilters = selectedFilters.servers;
  const categoriesFilters = selectedFilters.categories;
  const externalCategories = ['system', 'network', 'license'];


  const eventArtecoId = event?.deviceType === ARTECO_SER_CODE
    ? event?.serverCodename
    : event?.artecoId || `${event?.serverCodename}_${event?.deviceType}_${event?.deviceId}`;

  const isExternalCategory = externalCategories.includes(event.category);

  if (!isExternalCategory && !serverDevices[eventArtecoId]) {
    return false;
  }
  let paramsToUse = isExternalCategory ? t(`${event.params}`) : event.params;
  
  if (event.category === 505) {
    paramsToUse = processEventParams(paramsToUse, t);  
  }
  if(event.category === 307) {
    paramsToUse =  t('EVT_307_WRAP', { recpath: event.params})
  }
  const eventId = event.id || event._id;
  const textMatch = matchesSearchTerms(paramsToUse, searchTerms, strict, serverDevices, eventArtecoId, eventId);

  const categoryMatch = categoriesFilters.length > 0 ? categoriesFilters.includes(event.category) : diagnosticCategories.includes(event.category);
  const serverMatch = serverFilters.length === 0 || serverFilters.includes(event.serverCodename);
  const disconnectServersMatch = selectedFilters.disconnectServersEvent ? !serverCodenames.includes(event.serverCodename) : true;

  const combinedServerMatch = serverFilters.length !== 0 && selectedFilters.disconnectServersEvent 
    ? (serverMatch || disconnectServersMatch)
    : (serverMatch && disconnectServersMatch);

  return textMatch && categoryMatch && combinedServerMatch;

}


export function vcaFilterFunction (event,selectedFilters, enableNewEventFilter = false){
  // vca forensich search !

  const vcaSmartSearchData = {
    classes: enableNewEventFilter 
      ? (selectedFilters['VIDEO ANALYSIS EVENTS']?.vcaClasses || []) 
      : selectedFilters.vcaClasses,
    colors: enableNewEventFilter 
      ? (selectedFilters['VIDEO ANALYSIS EVENTS']?.vcaColors || []) 
      : selectedFilters.vcaColors,
    classConfidence: 1, // currently hardcoded to minimum
    colorConfidence: 1, // currently hardcoded to minimum
  };

  const filtersDisabled = (vcaSmartSearchData.classes.length == 0 && vcaSmartSearchData.colors.length == 0);

  if (filtersDisabled) {
    // non è un evento vca
    return true;
  }

  if (event?.eventData?.analytics == undefined) {
    return false;
  }

  let foundClass = true;
  let foundColors = true;
  let foundAdditionalAnalytics = false;

  if (vcaSmartSearchData.classes) {
    const minClassConfidenceLevel = vcaSmartSearchData.classConfidence / 100; // livello minimo di confidenza per un colore altrimenti ignoro !

    const classThreSholdList = event?.eventData?.analytics?.dlclassification.filter(classObj =>
      parseFloat(classObj.confidence) > minClassConfidenceLevel
    )
    const eventClassListNames = classThreSholdList.map(a => a.class.toLowerCase());
    const userClassFilterList = vcaSmartSearchData.classes.map(a => a.toLowerCase());

    foundClass = (userClassFilterList.length == 0) || userClassFilterList.some(r => eventClassListNames.indexOf(r) >= 0);

  }

  // colors filter
  if (vcaSmartSearchData.colors) {
    const minColorConfidenceLevel = vcaSmartSearchData.colorConfidence / 100; // livello minimo di confidenza per un colore altrimenti ignoro !

    const colorThreSholdList = event?.eventData?.analytics?.colourSignature.filter(colorObj =>
      parseFloat(colorObj.proportion) > minColorConfidenceLevel
    )

    const eventColorsListNames = colorThreSholdList.map(a => a.color.toLowerCase());
    const userColorsFilterList = vcaSmartSearchData.colors.map(a => a.toLowerCase());

    foundColors = (userColorsFilterList.length == 0) || userColorsFilterList.some(r => eventColorsListNames.indexOf(r) >= 0);
  }

  //additional analytics addons
  const classMapping = {
    "Hardhat": 'hard hat',
    // Add more mappings as needed
  };


  for (const [key, value] of Object.entries(classMapping)) {
    if (vcaSmartSearchData.classes.includes(key)) {
      const additionalEventName = event?.eventData?.analytics?.name.toLowerCase();
      if (additionalEventName.includes(value)) {
        foundAdditionalAnalytics = true;
        break;
      }
    }
  }

  return (foundClass || foundAdditionalAnalytics) && foundColors;
}



const kseniaFilterFunction = (event,selectedFilters, enableNewEventFilter) => {

  const kseniaSmartSearchData = enableNewEventFilter
  ? {
      states: Object.values(selectedFilters).flatMap(filterGroup => filterGroup.intrusionStatus || []),
      types: Object.values(selectedFilters).flatMap(filterGroup => filterGroup.intrusionTypes || []),
    }
  : {
      states: selectedFilters.intrusionStatus,
      types: selectedFilters.intrusionTypes,
    };

  const filtersDisabled = (kseniaSmartSearchData.states.length == 0 && kseniaSmartSearchData.types.length == 0);

  if (filtersDisabled) {
    // non è un evento ksenia
    return true;
  }

  if (event?.eventData?.kseniaData == undefined) {
    return false;
  }

  let foundState = true;
  let foundType = true;

  if (kseniaSmartSearchData.states) {
    // states = categories
    const currentClass = event?.eventData?.category;
    foundState = kseniaSmartSearchData.states.includes(currentClass);

  }

  // c'è un problema quando il match del figlio viene vincolato dal padre, va verificato

  if (kseniaSmartSearchData.types) {
    // types = subCategory
    const currentType = event?.eventData?.subCategory;
    foundType = kseniaSmartSearchData.types.includes(currentType);

  }

  let logic = true;

  if(kseniaSmartSearchData.states.length > 0 && kseniaSmartSearchData.types.length == 0){ // gli stati sono stati selezionati
    logic = foundState;
  }

  if(kseniaSmartSearchData.states.length == 0 && kseniaSmartSearchData.types.length > 0){ // i tipi sono stati selezionati
    logic = foundType;
  }

  if(kseniaSmartSearchData.types.length > 0 && kseniaSmartSearchData.states.length > 0 ){ // entrambi sono stati selezionati
    logic = enableNewEventFilter ? (foundState || foundType) : (foundState && foundType);
  }

  return logic;

}

// const noFiltersSet = (selectedFilters, live, searchTerms, enableNewEventFilter) => {

//   const checkFilters = (filters) => {
//     if (filters.categories && filters.categories.length) return false;
//     if (filters.servers && filters.servers.length) return false;
//     if (filters.vcaClasses && filters.vcaClasses.length) return false;
//     if (filters.vcaColors && filters.vcaColors.length) return false;
//     if (filters.intrusionStatus && filters.intrusionStatus.length) return false;
//     if (filters.intrusionTypes && filters.intrusionTypes.length) return false;
//     if (filters.onlyActiveLayoutEvents) return false;
//     if (enableNewEventFilter) {
//       if (filters.accessControlEventTypes && filters.accessControlEventTypes.length) return false;
//       if (filters.accessControlEventDirection && filters.accessControlEventDirection.length) return false;
//     }
//     return true;
//   };

//   if (enableNewEventFilter) {
//     if (Object.values(selectedFilters).some(filterGroup => !checkFilters(filterGroup))) {
//       return false;
//     }
//   } else {
//     if (!checkFilters(selectedFilters)) {
//       return false;
//     }
//   }

//   if (!live) return false;
//   if (searchTerms.length) return false;

//   return true;
// }

const noFiltersSet = (selectedFilters, live, searchTerms, enableNewEventFilter) => {
  if (enableNewEventFilter) {
    if (selectedFilters["ACCESS CONTROL EVENTS"]?.accessControlEventDirection?.length) return false;
    if (selectedFilters["ACCESS CONTROL EVENTS"]?.accessControlEventTypes?.length) return false;
    if (selectedFilters["ACCESS CONTROL EVENTS"]?.categories?.length) return false;
    
    if (selectedFilters["INTRUSION EVENTS"]?.intrusionStatus?.length) return false;
    if (selectedFilters["INTRUSION EVENTS"]?.intrusionTypes?.length) return false;

    if (selectedFilters["IO EVENTS"]?.categories?.length) return false;

    if (selectedFilters["VIDEO ANALYSIS EVENTS"]?.categories?.length) return false;
    if (selectedFilters["VIDEO ANALYSIS EVENTS"]?.lprCategories?.length) return false;
    if (selectedFilters["VIDEO ANALYSIS EVENTS"]?.vcaClasses?.length) return false;
    if (selectedFilters["VIDEO ANALYSIS EVENTS"]?.vcaColors?.length) return false;

    if (selectedFilters.servers?.codeNames?.length) return false;
    if (selectedFilters.servers?.onlyActiveLayoutEvents) return false;
  } else {
    if (selectedFilters.categories && selectedFilters.categories.length) return false;
    if (selectedFilters.servers && selectedFilters.servers.length) return false;

    if (selectedFilters.vcaClasses && selectedFilters.vcaClasses.length) return false;
    if (selectedFilters.vcaColors && selectedFilters.vcaColors.length) return false;

    if (selectedFilters.intrusionStatus && selectedFilters.intrusionStatus.length) return false;
    if (selectedFilters.intrusionTypes && selectedFilters.intrusionTypes.length) return false;

    if (selectedFilters.onlyActiveLayoutEvents) return false;
  }

  if (!live) return false;
  if (searchTerms.length) return false;

  return true;
};
